<template>
    <div class="future-wrapper" id="ai-features">
        <div class="future-container">
            <div class="future-title" data-aos="fade-up" data-aos-easing="ease-in-out">
                Embrace the future<br />with ChatGPT technology
            </div>
            <div class="future-item future-item_row" data-aos="fade-up" data-aos-easing="ease-in-out"
                data-aos-duration="1500">
                <div class="future-item__img">
                    <img src="../../../images/embrace-future/375/human-language.webp" />
                </div>
                <div class="future-item_content">
                    <div class="future-item__title">
                        Next-level language<br />understanding
                    </div>
                    <div class="future-item__info">
                        Experience a new era in human language understanding and
                        processing with cutting-edge ChatGPT technology. Our
                        chatbot assistant provides instant, accurate answers to
                        your questions in plain, everyday language. Unlock your
                        potential by enhancing your productivity, learning, and
                        creativity with AskGPT.
                    </div>
                </div>
            </div>
            <div class="future-item future-item_row-reverse" data-aos="fade-up" data-aos-easing="ease-in-out"
                data-aos-duration="1500">
                <div class="future-item__img">
                    <img src="../../../images/embrace-future/375/limitless-possibilities.webp" />
                </div>
                <div class="future-item_content">
                    <div class="future-item__title">
                        Unleash your<br />creative potential
                    </div>
                    <div class="future-item__info">
                        AskGPT is not just a chatbot; it's your ultimate
                        personal assistant for education, writing, programming,
                        and creative endeavors. From essay writing to unraveling
                        complex programs, and even crafting unique projects, the
                        web tool empowers you to accomplish more while saving
                        precious hours of your time and effort.
                    </div>
                </div>
            </div>
            <div class="future-item future-item_row" data-aos="fade-up" data-aos-easing="ease-in-out"
                data-aos-duration="1500">
                <div class="future-item__img">
                    <img src="../../../images/embrace-future/375/multilingual-communication.webp" />
                </div>
                <div class="future-item_content">
                    <div class="future-item__title">
                        Break language<br />barriers with ease
                    </div>
                    <div class="future-item__info">
                        Powered by the latest ChatGPT technology, AskGPT offers
                        an amazingly human-like chat experience. Fluent in
                        multiple languages, it effortlessly breaks down
                        barriers, making information accessible to everyone,
                        everywhere. Speed up your learning and dive into new
                        creative and tech adventures with AskGPT!
                    </div>
                </div>
            </div>
            <div class="future-item future-item_row-reverse" data-aos="fade-up" data-aos-easing="ease-in-out"
                data-aos-duration="1500">
                <div class="future-item__img">
                    <img src="../../../images/embrace-future/375/see-hear-speak.webp" />
                </div>
                <div class="future-item_content">
                    <div class="future-item_hint">Coming Soon</div>
                    <div class="future-item__title">
                        Сan see, hear,
                        <br class="hidden xxl:block" />
                        and speak
                    </div>
                    <div class="future-item__info">
                        From emails and social media posts to personal letters
                        and academic essays, AI ChatBot is your go-to tool for
                        both personal and professional writing. Soon, you'll be
                        able to capture an image of any text, and our advanced
                        chatbot will instantly analyze and recognize it,
                        providing you with quick and accurate results.
                        <br />
                        Simply speak your requests, and let your AI assistant
                        take care of the rest.
                        <br />
                        Enjoy effortless assistance wherever you are, whenever
                        you need it!
                    </div>
                </div>
            </div>
            <div class="future-button__container" data-aos="fade-up" data-aos-easing="ease-in-out"
                data-aos-duration="1500">
                <button class="future-button" @click="getStartedButtonClicked">
                    Get Started
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { START_NOW_LINK } from '@/Components/menuItems.js'
import { trackEvent } from '@/Utils/analytics.js'

export default {
    data() {
        return {
            startNowLink: START_NOW_LINK,
        }
    },
    methods: {
        getStartedButtonClicked() {
            trackEvent('start_page_pressed')
            trackEvent('get_started', null, 'fb')

            if (this.user) {
                this.$inertia.visit(route('chat.index'))
            } else {
                this.$inertia.visit(this.startNowLink)
            }
        },
    },
    computed: {
        user() {
            return this.$page.props.auth.user
        },
    },
}
</script>

<style lang="scss" scoped>
.future-wrapper {
    @apply w-full bg-[white];
}

.future-container {
    @apply bg-[white] px-[20px] w-[375px] mt-0 m-auto;

    @screen lg {
        @apply mx-auto py-[80px] w-[1280px];
    }
}

.future-title {
    @apply font-sans text-[32px] font-bold leading-[38px] tracking-[0px] text-center px-[20px] pt-[40px] text-[#0F1011];

    @screen lg {
        @apply text-[54px] font-bold leading-[66px] w-[750px] mx-auto my-0 pb-[42px];
    }
}

.future-item {
    @apply flex flex-col mt-[48px];

    @screen lg {
        @apply mt-[80px] gap-[60px];
    }
}

.future-item_row {
    @screen lg {
        @apply flex-row;
    }
}

.future-item_row-reverse {
    @screen lg {
        @apply flex-row-reverse;
    }
}

.future-item__img {
    @screen lg {
        @apply min-w-[610px] max-w-[545px];
    }
}

.future-item_content {
    @apply px-[20px] py-0;

    @screen lg {
        @apply p-0 flex flex-col self-center;
    }
}

.future-item_hint {
    @apply w-fit mb-[24px] py-[8px] px-[13px] rounded-[100px] bg-[#F9F4FF] text-[18px] leading-[21px] font-[600] text-[#8F58D1];
}

.future-item__title {
    @apply font-sans;
    @apply mt-[16px] text-[28px] font-bold leading-[30px] tracking-[0px] text-left;

    @screen lg {
        @apply text-[48px] leading-[52px] mt-0;
    }
}

.future-item__info {
    @apply font-sans mt-[9px] text-[16px] font-normal leading-[24px] tracking-[0px] text-left;

    @screen lg {
        @apply mt-[24px] text-[20px] leading-[32px];
    }
}

.future-button__container {
    @apply flex justify-center mt-[48px] pb-[48px];

    @screen lg {
        @apply mt-[80px] pb-0;
    }
}

.future-button {
    @apply font-sans w-[335px] h-[68px] text-[20px] font-bold leading-[24px] tracking-[0px] text-center text-white rounded-[16px];
    background: linear-gradient(90deg, #0fab6d 0%, #6c3da9 100%);
    box-shadow: 0 0 4px 0 #00000018;
    transition: background 1s ease-out;

    &:hover {
        background: linear-gradient(270deg, #0fab6d 0%, #6c3da9 100%);
    }
}
</style>
