<template>
    <div class="footer-wrapper">
        <div class="footer-container">
            <div class="footer-menu">
                <button
                    class="menu__logo"
                    @click="$inertia.visit(route('index'))"
                >
                    <img
                        alt="logo"
                        width="48"
                        height="48"
                        src="../../../../resources/images/logo-small.webp"
                    />
                    <span class="menu__logo-title"> AskGPT </span>
                </button>
                <div class="menu__items">
                    <a
                        v-for="item in menuItems"
                        class="menu__link"
                        :href="item.href"
                    >
                        {{ item.title }}
                    </a>
                </div>
                <button class="menu_button" @click="getStartedButtonClicked">
                    Start now for free
                </button>
            </div>
            <div class="footer-copyright mt-[22px]">
                A-PLAYERSTECH Ltd, Loizou Askani 8, 3110, Limassol, Cyprus
            </div>
            <div class="footer-copyright">Copyright © 2024</div>
        </div>
    </div>
</template>

<script>
import {FOOTER_MENU_ITEMS, START_NOW_LINK} from '@/Components/menuItems.js'
import {trackEvent} from '@/Utils/analytics.js'

export default {
    data() {
        return {
            menuItems: FOOTER_MENU_ITEMS,
            startNowLink: START_NOW_LINK,
        }
    },
    methods: {
        getStartedButtonClicked() {
            trackEvent('start_page_pressed')
            trackEvent('get_started', null, 'fb')

            if (this.user) {
                this.$inertia.visit(route('chat.index'))
            } else {
                this.$inertia.visit(this.startNowLink)
            }
        },
    },
    computed: {
        user() {
            return this.$page.props.auth.user
        },
    },
}
</script>

<style lang="scss" scoped>
.footer-wrapper {
    @apply w-full bg-[#000000];
}

.footer-container {
    @apply px-[20px] py-[40px] bg-[#000000] w-[375px] mt-0 m-auto;

    @screen lg {
        @apply mx-auto pt-[40px] pb-[24px] w-[1280px];
    }
}

.footer-menu {
    @apply flex flex-col items-center justify-center;

    @screen lg {
        @apply flex-row justify-between items-start;
    }
}

.menu__logo {
    @apply flex flex-row gap-[8px] items-center;

    @screen lg {
        @apply mt-[-8px];
    }
}

.menu__logo-title {
    @apply font-sans text-[20px] font-bold leading-[24px] tracking-[0px] text-left;
    background: linear-gradient(90deg, #69cbaa 0%, #b39dff 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.menu__items {
    @apply flex flex-wrap items-center justify-center gap-[10px] w-full mt-[24px];

    @screen lg {
        @apply grid grid-cols-5 mt-0 gap-1 w-[70%] mr-[-120px];
    }
}

.menu__link {
    @apply max-w-[50%] font-sans text-[16px] font-normal leading-[32px] tracking-[0px] text-left text-[#0FAB6D];

    &:hover {
        @apply underline;
    }

    @screen lg {
        @apply w-max;
        max-width: 100%;
    }
}

.menu_button {
    @apply font-sans w-[335px] h-[68px] mt-[24px] text-[16px] font-bold leading-[20px] tracking-[0px] text-center text-white rounded-[16px];
    background: linear-gradient(90deg, #0fab6d 0%, #6c3da9 100%);
    box-shadow: 0 0 4px 0 #00000018;
    transition: background 1s ease-out;

    &:hover {
        background: linear-gradient(270deg, #0fab6d 0%, #6c3da9 100%);
    }

    @screen lg {
        @apply w-[250px] h-[40px] text-[16px] leading-[19px] mt-0 rounded-[10px];
    }
}

.footer-copyright {
    @apply font-sans text-[14px] font-normal leading-[32px] tracking-[0px] text-center text-white opacity-50;
}
</style>
